import {HeaderBarNewRegistration} from "../../utils/Colors";
import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Snackbar,
    TextField,
    Tooltip,
    Typography,
    useTheme
} from "@mui/material";
import React, {useState} from "react";
import {AdminUserDataApiFp, GroupData} from "../../generated";
import {ContractorDropdown, RoleDropdown} from "./PopupComponents";
import {UserData} from "./UserList";
import {
    emailRegex,
    invalidFirstnameMessage,
    invalidLastnameMessage,
    invalidMailMessage, invalidPasswordMessage,
    invalidUsernameMessage, passwordRegex
} from "./UserManagementConstants";

interface UserCreationPopupProps {
    isOpen: boolean,
    setOpen: (open: boolean) => void,
    availableGroups: GroupData[],
    allUsers: UserData[]
}

export function UserCreationPopupButton({isOpen, setOpen, availableGroups, allUsers}: UserCreationPopupProps) {
    const theme = useTheme();

    const [username, setUsername] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [selectedGroup, setSelectedGroup] = useState<GroupData | null>(null);
    const [company, setCompany] = useState<string[]>([]);
    const [tempPassword, setTempPassword] = useState('');
    const [successMessage, setSuccessMessage] = useState(false);
    const [errorFields, setErrorFields] = useState({
        username: false,
        firstName: false,
        lastName: false,
        email: false,
        role: false,
        tempPassword: false
    });
    const [isLoading, setIsLoading] = useState(false);

    const resetForm = () => {
        setUsername('');
        setFirstName('');
        setLastName('');
        setEmail('');
        setSelectedGroup(null);
        setCompany([]);
        setTempPassword('');
        setErrorFields({
            username: false,
            firstName: false,
            lastName: false,
            email: false,
            role: false,
            tempPassword: false
        });
    };

    const handleSave = async () => {
        setIsLoading(true);

        const errors = {
            username: false,
            firstName: false,
            lastName: false,
            email: false,
            role: false,
            tempPassword: false
        };
        if (!username || allUsers.find(u => u.Output.Data.Username === username)) errors.username = true;
        if (!firstName) errors.firstName = true;
        if (!lastName) errors.lastName = true;
        if (!email || !emailRegex.test(email) || allUsers.find(u => u.Output.Data.Email === email)) errors.email = true;
        if (!selectedGroup) errors.role = true;
        if (!tempPassword || !passwordRegex.test(tempPassword)) errors.tempPassword = true;

        setErrorFields(errors);

        if (Object.values(errors).some((error) => error)) {
            setIsLoading(false);
            return;
        }

        const userInput = {
            Data: {
                Username: username,
                FirstName: firstName,
                LastName: lastName,
                Email: email,
                Verified: false,
                Enabled: true,
                Companies: company
            },
            TempPw: tempPassword
        };

        AdminUserDataApiFp().adminUserDataCreateUserSendRequest(userInput)
            .then(() => AdminUserDataApiFp().adminUserDataGetUserByNameAndGetData(userInput.Data.Username))
            .then(createdUser => {
                if (selectedGroup) AdminUserDataApiFp().adminUserDataUpdateUserGroupAndGetData(createdUser.Id, selectedGroup.Id)
            }).then(() => {
                setOpen(false);
                setSuccessMessage(true);
                resetForm();
            }).catch(e => console.error('Error creating user:', e))
            .finally(() => setIsLoading(false));
    };

    return (
        <>
            <Tooltip title={<Typography>Nutzer anlegen</Typography>}>
                <Button
                    variant='contained'
                    style={{backgroundColor: HeaderBarNewRegistration}}
                    size="large"
                    sx={{width: 215, height: 45}}
                    onClick={() => setOpen(true)}
                >
                    Nutzer anlegen
                </Button>
            </Tooltip>
            <Dialog open={isOpen} onClose={() => setOpen(false)}>
                <DialogTitle>Nutzer anlegen</DialogTitle>
                <DialogContent>
                    <Box component="form" noValidate autoComplete="off">
                        <FormControl fullWidth margin="normal">
                            <TextField
                                label="Nutzername"
                                variant="outlined"
                                required
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                error={errorFields.username}
                                helperText={errorFields.username ? invalidUsernameMessage : ''}
                            />
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <TextField
                                label="Vorname"
                                variant="outlined"
                                required
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                error={errorFields.firstName}
                                helperText={errorFields.firstName ? invalidFirstnameMessage : ''}
                            />
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <TextField
                                label="Nachname"
                                variant="outlined"
                                required
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                error={errorFields.lastName}
                                helperText={errorFields.lastName ? invalidLastnameMessage : ''}
                            />
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <TextField
                                label="Email"
                                variant="outlined"
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                error={errorFields.email}
                                helperText={errorFields.email ? invalidMailMessage : ''}
                            />
                        </FormControl>
                        <RoleDropdown
                            selectedGroup={selectedGroup}
                            availableGroups={availableGroups}
                            theme={theme} errorFields={errorFields}
                            setSelectedGroup={setSelectedGroup}
                        />
                        <ContractorDropdown
                            selectedContractor={company}
                            setSelectedContractor={setCompany}
                        />
                        <FormControl fullWidth margin="normal">
                            <TextField
                                label="Temp Passwort"
                                variant="outlined"
                                required
                                value={tempPassword}
                                onChange={(e) => setTempPassword(e.target.value)}
                                error={errorFields.tempPassword}
                                helperText={errorFields.tempPassword ? invalidPasswordMessage : ''}
                            />
                        </FormControl>
                    </Box>
                <Typography variant="caption" color="textSecondary">
                    Hinweis: Die Bestätigungsmail wird erst beim ersten Anmeldeversuch abgeschickt.
                </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} variant="contained" color="secondary" disabled={isLoading}>
                        Abbrechen
                    </Button>
                    <Button onClick={handleSave} variant="contained" color="primary" disabled={isLoading}>
                        Speichern
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={successMessage}
                autoHideDuration={3000}
                onClose={() => setSuccessMessage(false)}
            >
                <Alert onClose={() => setSuccessMessage(false)} severity="success" sx={{width: '100%'}}>
                    Nutzer erfolgreich angelegt!
                </Alert>
            </Snackbar>
        </>
    );
}
